import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import ArrowRight from '@material-ui/icons/KeyboardArrowRight'
import ButtonBase from '@material-ui/core/Button'
import { PropTypes as MaterialUIPropTypes, Typography } from '@material-ui/core'
import classNames from 'classnames'
import Dialog from '@material-ui/core/Dialog'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import CircularProgress from '@material-ui/core/CircularProgress'
import CloseIcon from '@material-ui/icons/Close'

import i18n from '../../i18n'
import { OrderCTA, OrderItemDisplayStates, OrderItemStates } from '../../store/items/types'
import { GhostLink } from '../../utils/link'
import Scheduler from './Scheduler'
import { MacroState } from '../../utils/mapper'
import { getClientUrl } from '../../utils/consts'
import { UploadDocTypeMenu } from '../../store/uploads/types'
import { differenceInMinutes } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import useUserInfoState from '../common/useUserInfo'
import Button from '../common/Button'
import { AppState } from '../../store'
import { Message, MessageContexts } from '../../store/messages/types'
import { messageByContextIdSelector } from '../../selectors/messages'

type CTAAttributes = {
  label: string
  color: MaterialUIPropTypes.Color
  arrow?: boolean
  route: '/messages' | '/documents' | ''
  isDisabled?: boolean
}

type StatusMapping = {
  [key in OrderCTA]: CTAAttributes
}

// TODO: implement all the states, remove ts-ignore
// @ts-ignore
const CONSTANTS: StatusMapping = {
  [OrderItemStates.SendIntro]: {
    label: i18n.t('order__button__send__intro'),
    color: 'primary',
    arrow: true,
    route: '/messages',
  },
  [OrderItemStates.SendMessage]: {
    label: i18n.t('order__button__send__message'),
    color: 'primary',
    arrow: true,
    route: '/messages',
  },
  [OrderItemStates.SendFirstDraft]: {
    label: i18n.t('order__button__send__first__draft'),
    color: 'primary',
    arrow: true,
    route: '/messages',
  },
  [OrderItemStates.SendLinkedin]: {
    label: i18n.t('order__button__send__linkedin'),
    color: 'primary',
    arrow: true,
    route: '/messages',
  },
  [OrderItemStates.Revisions]: {
    label: i18n.t('order__button__in_revisions'),
    color: 'primary',
    arrow: false,
    route: '/messages',
  },
  [OrderItemStates.AwaitingReply]: {
    label: i18n.t('order__button__awaiting'),
    color: 'secondary',
    route: '/messages',
  },
  [OrderItemStates.SendSummary]: {
    label: i18n.t('order__button__send__summary'),
    color: 'primary',
    route: '/messages',
  },
  [OrderItemStates.ConductCall]: {
    label: i18n.t('order__button__call'),
    color: 'primary',
    route: '',
  },
  [OrderItemStates.ConductInterview]: {
    label: i18n.t('order__button__interview'),
    color: 'primary',
    route: '',
  },
  [OrderItemStates.ScheduleInterview]: {
    label: i18n.t('order__button__schedule_interview'),
    color: 'primary',
    route: '',
  },
  [OrderItemStates.SchedulePhoneCall]: {
    label: i18n.t('order__button__schedule_call'),
    color: 'primary',
    route: '',
  },
  [OrderItemStates.Canceled]: {
    label: i18n.t('order__button__cancelled'),
    color: 'secondary',
    route: '',
  },
  [OrderItemStates.Refunded]: {
    label: i18n.t('order__button__refunded'),
    color: 'secondary',
    route: '',
  },
  [OrderItemDisplayStates.CloseOrder]: {
    label: i18n.t('order__button__close_order'),
    color: 'primary',
    arrow: true,
    route: '/messages',
  },
  [OrderItemStates.Closed]: {
    label: i18n.t('order__button__farewell'),
    color: 'primary',
    arrow: true,
    route: '/messages',
  },
  [OrderItemDisplayStates.None]: {
    label: i18n.t('order__button__none'),
    color: 'primary',
    arrow: true,
    route: '',
  },
  [OrderItemStates.Paid]: {
    label: i18n.t('order__button__paid'),
    color: 'secondary',
    arrow: false,
    route: '',
  },
  [OrderItemStates.Reassigned]: {
    label: i18n.t('order__button__reassigned'),
    color: 'secondary',
    arrow: false,
    route: '',
  },
  [OrderItemStates.AwaitingReview]: {
    label: i18n.t('order__button__awaiting_review'),
    color: 'secondary',
    arrow: false,
    route: '',
  },
  [OrderItemStates.UploadForEditor]: {
    label: i18n.t('order__button__upload_editor'),
    color: 'primary',
    arrow: true,
    route: '/documents',
  },
  [OrderItemStates.AwaitingScheduling]: {
    label: i18n.t('order__button__awaiting_scheduling'),
    color: 'secondary',
    arrow: false,
    route: '',
  },
  [OrderItemStates.AwaitingConfirmation]: {
    label: i18n.t('order__button__awaiting_confirmation'),
    color: 'secondary',
    arrow: false,
    route: '',
  },
}

// TODO: remove showScheduling feature flag when it's time
const getCTAAttributes = (
  macroState: MacroState,
  userTimezone: string,
  showScheduling: boolean,
  meetingID?: number | null
): CTAAttributes => {
  if (
    showScheduling &&
    (macroState.CTA === OrderItemStates.ConductInterview || macroState.CTA === OrderItemStates.ConductCall) &&
    !!meetingID &&
    !!macroState.dueDate
  ) {
    // determine if the CTA should be active
    const minutesDiff = differenceInMinutes(utcToZonedTime(macroState.dueDate, userTimezone), new Date())

    let labelKey = 'order__button__call_scheduled'
    if (minutesDiff <= -30) {
      labelKey = 'schedule_order_reschedule_cta'
    } else if (minutesDiff <= 15) {
      labelKey = 'schedule_order_start_call_cta'
    }

    return {
      label: i18n.t(labelKey),
      color: 'secondary',
      arrow: false,
      route: '',
      isDisabled: true,
    }
  } else {
    return CONSTANTS[macroState.CTA]
  }
}

interface OrderActionProps {
  macroState: MacroState
  clientID: number
  onClick: () => void
  onSchedule: (date: Date, itemID: number) => void
  schedulerOptions?: UploadDocTypeMenu[]
  meetingID?: number | null
  size?: string
  loading: boolean
  hideFarewell: boolean
  isResumeEdit: boolean
  hasResumeEditDoc: boolean
}

const useStyles = makeStyles(theme => ({
  closed: {
    textAlign: 'center',
    textTransform: 'initial',
    width: '12.5rem',
    '@media (max-width: 768px)': {
      width: 'initial',
      textAlign: 'left',
      padding: '0.563rem 0.4rem',
    },
    '&.farewell': {
      marginBottom: '0.5rem',
    },
  },
  root: {
    margin: theme.spacing(1),
    padding: `0.563rem 0`,
    width: '12.5rem',
    textTransform: 'initial',
    borderWidth: '1px',
    borderStyle: 'solid',
    '&.full': { width: '100%' },
    '@media (max-width: 1050px)': {
      '&:not(.full)': { width: 'initial', padding: '0.563rem 0.4rem' },
    },
    '& .button': {
      padding: `${theme.spacing(2.7)}px 0`,
      width: '100%',
      textTransform: 'initial',
      fontSize: '1rem',
      borderWidth: '1px',
      borderStyle: 'solid',
      fontWeight: 'bold',
    },
    '&.late': {
      borderColor: '#f5988b',
      color: '#ea4e38',
      '&:hover': {
        borderColor: '#ea4e38',
        backgroundColor: 'rgba(244, 143, 177, 0.1)',
      },
    },
  },
  icon: {
    width: '1rem',
    height: '1rem',
    marginLeft: '0.5rem',
    '@media (max-width: 1050px)': {
      display: 'none',
    },
  },
  container: {
    padding: '0px 20px 20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  closeButton: {
    alignSelf: 'flex-end',
  },
  submitButton: {
    marginTop: '12px',
  },
}))

const OrderAction: React.FC<OrderActionProps> = props => {
  const {
    clientID,
    macroState,
    onClick,
    size,
    onSchedule,
    schedulerOptions,
    meetingID,
    loading,
    hideFarewell,
    isResumeEdit,
    hasResumeEditDoc,
  } = props

  const { featureFlags, timeZone } = useUserInfoState()

  const classes = useStyles()

  const messages = useSelector<AppState, Message[]>(state =>
    messageByContextIdSelector(state.messagesReducer, MessageContexts.CLIENT, clientID)
  )

  const showFarewellButton =
    !messages.some(msg => [12, 20, 35, 36, 52, 53].includes(msg.variables?.template_id as number)) && !hideFarewell

  const [schedulerIsOpen, toggleSchedulerIsOpen] = useState<boolean>(false)
  const [showCloseOrderPopup, setShowCloseOrderPopup] = useState(false)

  const requireScheduler =
    macroState.CTA === OrderItemStates.ScheduleInterview || macroState.CTA === OrderItemStates.SchedulePhoneCall

  const isManualTracking =
    macroState.CTA === OrderItemStates.ConductInterview || macroState.CTA === OrderItemStates.ConductCall

  if (!macroState || !CONSTANTS[macroState.CTA]) {
    return null
  }

  const { color, route, label, arrow, isDisabled = false } = getCTAAttributes(
    macroState,
    timeZone,
    featureFlags.showScheduler,
    meetingID
  )
  const isLate =
    !([
      OrderItemStates.Paid,
      OrderItemStates.Canceled,
      OrderItemStates.Refunded,
      OrderItemStates.Closed,
      OrderItemStates.Reassigned,
    ] as OrderCTA[]).includes(macroState.CTA) &&
    !!macroState.dueDate &&
    new Date(macroState.dueDate) < new Date()

  const renderButton = (onClick?: () => void, showLoader = false, disabled = false) => (
    <ButtonBase
      variant="outlined"
      color={color}
      disabled={isDisabled || (showLoader && loading) || disabled}
      className={classNames({ late: isLate, [classes.root]: true, full: size === 'full' })}
      onClick={!!onClick ? onClick : () => {}}
      area-label="order_button"
    >
      {showLoader && loading ? (
        <CircularProgress color="primary" style={isLate ? { color: '#ea4e38' } : {}} size="1rem" />
      ) : (
        <>
          <Typography variant={'body2'}>{label}</Typography>
          {arrow ? <ArrowRight className={classes.icon} /> : ''}
        </>
      )}
    </ButtonBase>
  )

  if (requireScheduler && !!schedulerOptions) {
    return (
      <>
        <Scheduler
          options={schedulerOptions}
          onSubmit={async (date: Date, itemID: number) => await onSchedule(date, itemID)}
          isOpen={schedulerIsOpen}
          toggleIsOpen={(value: boolean) => toggleSchedulerIsOpen(value)}
        />
        {renderButton(() => toggleSchedulerIsOpen(true))}
      </>
    )
  }

  if (isManualTracking) {
    return renderButton(onClick)
  }

  if (macroState.CTA === OrderItemStates.Closed) {
    return (
      <>
        <Typography
          className={classNames({ [classes.closed]: true, farewell: showFarewellButton })}
          color={'secondary'}
          variant={'body2'}
        >
          {i18n.t('order__button__complete')}
        </Typography>
        {showFarewellButton ? (
          <GhostLink
            to={{ pathname: getClientUrl(clientID, route), state: macroState }}
            onClick={async () => await onClick()}
          >
            {renderButton(undefined, true)}
          </GhostLink>
        ) : null}
      </>
    )
  }

  if (macroState.CTA === OrderItemDisplayStates.CloseOrder) {
    return (
      <>
        {renderButton(() => setShowCloseOrderPopup(true), true, isResumeEdit && !hasResumeEditDoc)}

        {/* Close Order Confirmation Popup */}
        <Dialog
          onClose={() => setShowCloseOrderPopup(false)}
          aria-labelledby="ai-draft-popup"
          open={showCloseOrderPopup}
        >
          <IconButton
            className={classes.closeButton}
            type="button"
            edge="start"
            color="inherit"
            onClick={() => setShowCloseOrderPopup(false)}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Box className={classes.container}>
            <Typography>{i18n.t('order_close_popup_text')}</Typography>

            <GhostLink
              to={{ pathname: getClientUrl(clientID, route), state: macroState }}
              onClick={async () => await onClick()}
            >
              <Button
                className={classes.submitButton}
                type="primary"
                showLoader={false}
                area-label="confirm-close-order"
                onClick={() => setShowCloseOrderPopup(false)}
              >
                {i18n.t('confirm')}
              </Button>
            </GhostLink>
          </Box>
        </Dialog>
      </>
    )
  }

  return (
    <GhostLink
      to={{ pathname: getClientUrl(clientID, route), state: macroState }}
      onClick={async () => await onClick()}
    >
      {renderButton()}
    </GhostLink>
  )
}

export default OrderAction
